import Image from 'next/image'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getImage, imageLoader } from 'shared/utils'
import { RootState } from 'store'

const ErrorScreen = ({ statusCode = 0 }: { statusCode?: number }) => {
  const error = useSelector((state: RootState) => state.app.error)
  const { t } = useTranslation()
  const router = useRouter()

  const { data, status } = error || {}
  const errorCode = data?.code || status || statusCode

  if (errorCode === 4002) {
    // TODO: handle when activity not actually running - may cause by clock of user is actually faster
  }

  if (!errorCode) return null

  const handleRetry = () => {
    router.reload()
  }

  return (
    <div className="flex flex-col justify-center text-center absolute top-0 left-0 bg-white w-full h-screen text-normal leading-relaxed large:text-xl font-bold font-shopee">
      <div className="relative mb-4 large:mb-10 mx-auto w-28 h-28 large:w-52 large:h-52">
        <Image src={getImage('network-error.png')} alt="" draggable={false} layout="fill" loader={imageLoader} />
      </div>
      <p>{t('UI! CÓ LỖI XẢY RA MẤT RỒI ({{errorCode}})', { errorCode })}.</p>
      <p>{t('BẠN VUI LÒNG THỬ LẠI NHA')}!</p>
      <button className="text-primary large:text-xl py-1 px-5 mt-6" onClick={handleRetry} type="button">
        {t('Thử lại')}
      </button>
    </div>
  )
}

export default ErrorScreen
